import { faCalendar, faCalendarWeek, faDesktop, faKey, faKeyboard, faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import exterior from '../images/blainville.jpg';
import map from '../images/map.jpg';
import Office from './Office';
import ScrollToTop from './ScrollToTop';

const Listing = () => {

    return (
        <Container>
            <ScrollToTop />
            <Row className="mt-5">
                <Col xs="12" md="8">
                    <h3 className="mb-0 mt-1">Blainville</h3>
                    <span className="thinText">681 boul. Curé-Labelle, Blainville, QC</span><br /><br />
                    <img src={exterior} alt="Bureaux Blainville" className="imgBorder" height="150" />

                    <p className="mt-3">
                        Emplacement de choix, situé sur la rive-nord de Montréal, à proximité des autoroutes 15 et 640.<br />
                        Idéal pour les professionnels et les travailleurs autonomes.
                    </p>
                    <p>
                        Que ce soit pour le télétravail ou pour développer votre entreprise, profitez d'un espace dédié adapté à vos besoins! Toutes les locations de bureaux incluent un stationnement réservé et l'accès à une salle de conférence partagée.
                    </p>

                </Col>
                <Col xs="4" className="d-none d-sm-block">

                    <img src={map} alt="Carte Blainville" className="imgMap imgBorder" />
                </Col>
            </Row>

            <Office id="1" priceDuration="7">
                <span><FontAwesomeIcon icon={faKeyboard} /> Mobilier ergnomique</span><br />
                <span><FontAwesomeIcon icon={faCalendarWeek} /> Location courte durée</span><br />
                <span><FontAwesomeIcon icon={faDesktop} /> Écran ergonomique inclus</span><br />
            </Office>

            <Office id="2" priceDuration="30">
                <span><FontAwesomeIcon icon={faKeyboard} /> Mobilier ergnomique</span><br />
                <span><FontAwesomeIcon icon={faCalendar} /> Location mensuelle</span><br />
                <span><FontAwesomeIcon icon={faKey} /> Espace fermé à clé</span><br />
            </Office>

            <Office id="3" priceDuration="30">
                <span><FontAwesomeIcon icon={faKeyboard} /> Mobilier ergnomique</span><br />
                <span><FontAwesomeIcon icon={faPencilRuler} /> Grand espace de travail</span><br />
                <span><FontAwesomeIcon icon={faKey} /> Espace fermé à clé</span><br />
            </Office>

        </Container>
    );
}

export default Listing;