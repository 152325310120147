import React, { useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import exterior from '../images/blainville.jpg';
import ScrollToTop from './ScrollToTop';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [sent, setSent] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

    const sendContact = async (e) => {
        e.preventDefault();
        setBtnDisabled(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: name, email: email, phone: phone })
        };

        const response = await fetch('/api/contact', requestOptions);
        if (response.status !== 200)
            console.log("An error occured during request");
        else
            setSent(true);

        setBtnDisabled(false);
    }

    return (
        <Container>
            <ScrollToTop />
            <h3 className="mt-5 mb-3">Planifier une visite</h3>
            {sent &&
                <div className="officeBlue p-5">
                    <p>Votre demande a été envoyée.<br /><br />Nous communiquerons avec vous dans les plus bref délais.</p>
                </div>
            }
            {!sent &&
                <>
                    <div className="officeBlue">
                        <Row>
                            <Col xs="12" sm="6" className="p-5">
                                <p>Nous communiquerons avec vous dans les plus brefs délais.</p>
                                <Form onSubmit={(e) => sendContact(e)}>
                                    <FormGroup className="mt-2">
                                        <Label for="txtName">Nom</Label>
                                        <Input name="txtName" value={name} onChange={(e) => setName(e.target.value)} required="required" type="text" />
                                    </FormGroup>
                                    <FormGroup className="mt-2">
                                        <Label for="txtCourriel">Courriel</Label>
                                        <Input name="txtCourriel" value={email} onChange={(e) => setEmail(e.target.value)} required="required" type="email" />
                                    </FormGroup>
                                    <FormGroup className="mt-2">
                                        <Label for="txtTel">Téléphone</Label>
                                        <Input name="txtTel" value={phone} onChange={(e) => setPhone(e.target.value)} required="required" type="tel" />
                                    </FormGroup>
                                    <br />
                                    <Button type="submit" disabled={btnDisabled}>Envoyer</Button>
                                </Form>
                            </Col>
                        </Row>
                    </div>

                    <Row className="mt-3 d-block d-sm-none">
                        <Col xs="12" sm="6" className="mb-3">
                            <img src={exterior} alt="Bureaux Blainville" className="imgBorder" />
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
}

export default Contact;