import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import top from '../images/top.jpg';
import desks from '../images/top2.jpg';
import conf from '../images/conf-1.jpg';
import ScrollToTop from './ScrollToTop';

const About = () => {

    return (
        <Container>
            <ScrollToTop />
            <h3 className="mt-5 mb-3">À propos</h3>

            <Row className="mt-5">
                <Col xs="12" sm="8">
                    <h5>Salle de conférence</h5>
                    <p>Obtenez l'accès à une salle de conférence avec votre réservation. L'accès est partagé entre les occupants, afin de maximiser l'usage selon un mode partagé.<br /><br />Aucun frais supplémentaire et aucune banque d'heures à gérer.</p>
                </Col>
                <Col xs="12" sm="auto">
                    <img src={conf} className="smallImg officeBorder imgAppear" alt="Mobilier ergonomique" />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xs="12" sm="8">
                    <h5>Productivité</h5>
                    <p>Nous offrons des espaces lumineux et ergonomiques, afin de favoriser la productivité et la collaboration.
                        Tous nos espaces incluent des bureaux ajustables et des chaises de travail ergonomiques.<br /><br />De plus, plusieurs options de mobilier sont disponibles. Pour certains espaces, il peut être possible d'ajouter un deuxième bureau au besoin.</p>
                </Col>
                <Col xs="12" sm="auto">
                    <img src={desks} className="smallImg officeBorder imgAppear" alt="Mobilier ergonomique" />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xs="12" sm="8">
                    <h5>Flexibilité</h5>
                    <p>Des options flexibles de réservation sont offertes à tous nos clients. Notre offre peut s'adapter à la réalité de votre entreprise. Des termes courts ou de longue durée sont aussi possibles sur demande. Nos prix affichés inclus tout ce dont vous besoin pour démarrer, aucune surprise.</p>
                    <p>Selon les besoins de votre entreprise, des options d'affichage sont aussi disponible. Les réservations de longue durée bénéficie d'un affichage extérieur sur le vitrage des bureaux.</p>
                </Col>
                <Col xs="12" sm="auto">
                    <img src={top} className="smallImg officeBorder imgAppear" alt="Bureaux flexibles" />
                </Col>
            </Row>

        </Container>
    );
}

export default About;