import { faBriefcase, faCoffee, faDesktop, faDoorClosed, faKey, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import top from '../images/top.jpg';
import Listing from './Listing';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Row className="m-0">
                <Col className="p-0 d-none d-sm-block">
                    <div className="topImg"></div>
                </Col>
                <Col className="topText" xs="12" md="7">
                    <h2>Espaces bureaux / Coworking</h2>
                    <br />
                    <p>Réservez votre espace selon vos besoins, directement en ligne.</p>
                    <p className="topItems">
                        <span><FontAwesomeIcon icon={faDoorClosed} /> Bureaux fermés</span><br />
                        <span><FontAwesomeIcon icon={faBriefcase} /> Salle de conférence</span><br />
                        <span><FontAwesomeIcon icon={faWifi} /> Internet Gigabit illimité</span><br />
                        <span><FontAwesomeIcon icon={faDesktop} /> Espaces ergonomiques</span><br />
                        <span><FontAwesomeIcon icon={faCoffee} /> Cuisine partagée</span><br />
                        <span><FontAwesomeIcon icon={faKey} /> Libre-accès</span>
                    </p>
                    <br />
                    <Button className="visitBtn" onClick={() => navigate('/visite')}>Planifier une visite</Button>
                </Col>
            </Row>

            <Row className="d-xs-block d-sm-none m-0">
                <Col xs="12" className="p-0">
                    <img src={top} alt="Bureaux Blainville" className="imgAppear" width="100%" />
                </Col>
            </Row>
            <Listing />
        </div>
    );
}

export default Home;