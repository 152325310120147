import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import ScrollToTop from './ScrollToTop';

const Booking = () => {
    let { id } = useParams();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [duration, setDuration] = useState(0);
    const [price, setPrice] = useState("-");
    const [sent, setSent] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [disabledDays, setDisabledDays] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [officeClass, setOfficeClass] = useState("");
    const [officeClass2, setOfficeClass2] = useState("");

    useEffect(() => {
        setDisabledDays([
            {
                year: 2022,
                month: 2,
                day: 2,
            }]);

        setOfficeClass("office officeBorder office" + id);
        setOfficeClass2("office officeBorder office" + id + "-1");

        setDuration(30);
        fetchPrice(30);
    }, []);

    const sendBooking = async (e) => {
        e.preventDefault();

        if (selectedDay === null || duration === 0)
            return;

        setBtnDisabled(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                duration: duration,
                startDate: new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day),
                officeId: id
            })
        };

        const response = await fetch('/api/booking', requestOptions);
        if (response.status !== 200)
            console.log("An error occured during request");
        else
            setSent(true);

        setBtnDisabled(false);
    }

    const fetchPrice = async (d) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch('/api/booking/price?officeId=' + id + '&duration=' + d, requestOptions);
        if (response.status !== 200)
            console.log("An error occured during request");
        else {
            var json = await response.json();
            setPrice(json.price);
        }
    }

    const updateDuration = async (e) => {
        const d = e.target.value;
        await fetchPrice(d);
        setDuration(d);
    }

    return (
        <Container>
            <ScrollToTop />
            <h3 className="mt-5 mb-3">Réservation</h3>

            {!sent &&
                <div className="officeNavy p-5">
                    <h5>Bureau privé #{id}</h5>
                    <p>Veuillez compléter votre réservation.</p>
                    <br />
                    <Form onSubmit={(e) => sendBooking(e)}>
                        <Row>
                            <Col xs="12" sm="6">
                                <FormGroup className="mt-2">
                                    <Label for="txtName">Nom</Label>
                                    <Input name="txtName" value={name} onChange={(e) => setName(e.target.value)} required="required" type="text" />
                                </FormGroup>
                                <FormGroup className="mt-2">
                                    <Label for="txtCourriel">Courriel</Label>
                                    <Input name="txtCourriel" value={email} onChange={(e) => setEmail(e.target.value)} required="required" type="email" />
                                </FormGroup>
                                <FormGroup className="mt-2">
                                    <Label for="txtTel">Téléphone</Label>
                                    <Input name="txtTel" value={phone} onChange={(e) => setPhone(e.target.value)} required="required" type="tel" />
                                </FormGroup>
                                <FormGroup className="mt-2">
                                    <Label for="sltDuration">Durée de réservation</Label>
                                    <Input type="select" value={duration} onChange={(e) => updateDuration(e)} required="required" name="sltDuration">
                                        {id === "1" && <option value="7">1 semaine</option>}
                                        <option value="90">3 mois</option>
                                        <option value="180">6 mois</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="12" sm="6">
                                <FormGroup className="mt-2">
                                    <Label for="txtDate">Date de début</Label>
                                    <Calendar value={selectedDay}
                                        onChange={setSelectedDay}
                                        disabledDays={disabledDays}
                                        colorPrimary="#5DA0A9"
                                        colorPrimaryLight="#F5F3F5"
                                        calendarClassName="responsive-calendar"
                                        minimumDate={utils().getToday()}
                                        locale="en"
                                        shouldHighlightWeekends />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                                <span><b>{price}$ + taxes</b></span><br />
                                <FormGroup check className="mt-2">
                                    <Label check>
                                        <Input type="checkbox" required="required" />{' '}
                                        J'accepte les <Link to="/termes-conditions">termes et conditions</Link> liés à cette réservation.
                                    </Label>
                                </FormGroup>
                                <br />
                                <Button type="submit" disabled={btnDisabled}>Réserver</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }

            {sent &&
                <div className="officeBlue p-5">
                    <h5>Bureau privé #{id}</h5>
                    <p>Votre réservation est enregistrée.</p>

                    <b>Paiement</b>
                    <p>Vous recevrez votre facture par courriel, vous disposez de 48h pour acquitter le solde de votre réservation.<br />Sans quoi, votre réservation sera annulée.</p>

                    <b>Accès</b><p>Vous recevrez un lien permettant d'accéder aux bureaux 24h avant le début de votre réservation.</p>

                    <br />
                    <p>Au plaisir de vous voir prochainement!</p>
                </div>
            }


            <Row className="mt-3">
                <Col xs="12" sm="6" className="mb-3">
                    <div className={officeClass}></div>
                </Col>

                <Col xs="12" sm="6">
                    <div className={officeClass2}></div>
                </Col>
            </Row>

        </Container>
    );
}

export default Booking;