import React, { Component } from 'react';
import { Route, Routes } from 'react-router'
import Booking from './components/Booking';
import Listing from './components/Listing';
import Contact from './components/Contact';
import Terms from './components/Terms';
import Home from './components/Home';
import About from './components/About';
import Layout from './components/Layout';
import './custom.css';


export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/espaces' element={<Listing />} />
                    <Route path='/reserver/:id' element={<Booking />} />
                    <Route path='/visite' element={<Contact />} />
                    <Route path='/termes-conditions' element={<Terms />} />
                    <Route path='/a-propos' element={<About />} />
                </Routes>
            </Layout>
        );
    }
}