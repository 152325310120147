import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

const Office = (props) => {
    const navigate = useNavigate();

    const [price, setPrice] = useState(null);
    const [officeClass, setOfficeClass] = useState("");
    const [divClass, setDivClass] = useState("mt-4 officeNavy");

    useEffect(() => {
        fetchPrice();

        setOfficeClass("office office" + props.id);

        if (props.id === "1") {
            setDivClass("mt-4 officeBlue");
        }
        else if (props.id === "2") {
            setDivClass("mt-4 officeNavy");
        }
        else {
            setDivClass("mt-4 officeTurqoise");
        }
    }, []);

    const fetchPrice = async () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch('/api/booking/price?officeId=' + props.id + '&duration=' + props.priceDuration, requestOptions);
        if (response.status !== 200)
            console.log("An error occured during request");
        else {
            var json = await response.json();
            setPrice(json.price);
        }
    }

    return (
        <div className={divClass}>
            <Row>
                <Col xs="5" sm="8">
                    <div className={officeClass}></div>
                </Col>
                <Col className="officeTop" xs="7" sm="4">
                    <h4>Bureau privé #{props.id}</h4>
                    {props.children}
                    <br />
                    {/*{price && <>*/}
                    {/*    <span><b>{price}$/{props.priceDuration === "7" && "semaine"}{props.priceDuration === "30" && "mois"}</b></span><br />*/}
                    {/*</>*/}
                    {/*}*/}
                    {price > 0 &&
                        <Button onClick={() => navigate('/reserver/' + props.id)}>Réserver</Button>
                    }
                    {price < 0 && 
                        <span><i>Non-disponible</i></span>
                    }
                </Col>
            </Row>

        </div>
    );
}

export default Office;