import React from 'react';
import { Container } from 'reactstrap';
import ScrollToTop from './ScrollToTop';

const Terms = () => {

    return (
        <Container>
            <ScrollToTop />
            <h3 className="mt-5 mb-3">Termes et conditions</h3>
            <div className="officeTurqoise p-5">
                <h3>Modalités</h3>
                <p>Ci-dessous figurent les modalités générales de location des bureaux. Le terme "Locataire" réfère au client qui désire effectuer la location d'un bureau. Le terme "Locateur" se réfere au propriétaire des bureaux.</p>
                <h4>Bureaux et mobilier</h4>
                <p>Le Locataire est responsable de son accès et le doit le conserver pour son propre usage ou celui de ses employés. En aucun cas, le Locataire ne peut modifier ou transformer l'usage d'un bureau. Les dommages causés au mobilier ou aux infrastructures seront facturées au Locataire.
                    <br /><br />Le Locataire ne peut partager son code d'accès avec un tier ou effectuer la sous-location de son espace. Il doit protéger son code d'accès et sera considéré responsable en cas d'usurpation.
                    <br /><br />De plus, le Locataire doit prendre les mesures requises pour assurer son matériel et en protéger l'usage. Le Locateur ne peut être tenu responsable en cas de vol ou de bris.</p>

                <h4>Paiement</h4>
                <p>Le Locataire est tenu de payer les factures liées à ses locations, autrement l'accès aux bureaux ne pourra pas être maintenu.</p>

                <h4>Technologies</h4>
                <p>Le Locataire s'engage a effectuer un usage raisonnable des ressources matérielles, logicielles et réseaux fournies dans le cadre de la location. De plus, le Locateur ne peut être tenu responsable en cas de virus ou de problèmes de sécurité ciblant le Locataire.</p>

                <h4>Stationnement</h4>
                <p>Le Locateur s'engage à fournir un minimum de un (1) stationnement par bureau loué. Le Locataire doit utiliser les places désignés (à l'avant de l'immeuble) en tout temps.</p>

                <h4>Affichage</h4>
                <p>Le Locataire ne peut mettre en place d'affichage (intérieur/extérieur) sans l'approbation du Locateur.</p>
            </div>

        </Container>
    );
}

export default Terms;