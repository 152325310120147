import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

const Layout = (props) => {

    const year = new Date().getFullYear();

    return (
        <div>
            <NavMenu />
            <Container fluid className="p-0">
                {props.children}
            </Container>
            <div className="pt-5 mb-3 text-center">
                &copy; {year} EspacesBureaux.ca
            </div>
        </div>
    );
}

export default Layout;
